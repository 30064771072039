import React from 'react';
import { Routes, Route } from "react-router-dom";

import "./bootstrap.min.css";
import './App.css';
import { AnimatePresence } from 'framer-motion';
import 'reactjs-bottom-navigation/dist/index.css';

import didayz_name from "./assets/didayz/didayz_icone_name_white.png";
import whitedidayz from "./assets/didayz/whitedidayz.png";

import NewPassword from "./components/newPassword.component";
import Home from "./components/home.component";
import Prices from "./components/prices.component";
import LegalNotice from "./components/legalNotice.component";
import ContactUS from "./components/contact-us.component";
import Guide from "./components/guide.component";
import Blog from "./components/blog.component";

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import Divider from '@mui/material/Divider';

import { isFromPhone } from "./components/globalVariable.component";

function App() {

  return (
    <div style={{minHeight: "100vh"}}>
      <div style={{ minHeight: "100vh", display: "grid" }}>
        <AppBar position="sticky">
          <Toolbar sx={{ display: "flex", justifyContent: "space-between"}}>
            <img alt="Icône" src={didayz_name} className="cursorPointer" style={{ maxWidth: 100, justifySelf: "flex-start", paddingLeft: "calc(5px + 1vw)"}} onClick={() => {window.location.href=""}}></img>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2}}>
              <IconButton>
                <AppleIcon sx={{ color: "white" }}/>
              </IconButton>
              <IconButton onClick={() => { window.open('https://play.google.com/store/apps/details?id=fr.didayz.app', '_blank');  }}>
                <AndroidIcon sx={{ color: "white" }}/>
              </IconButton>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ bgcolor: "white" }} />
              <Button color="inherit" sx={{ fontFamily: "quasimoda" }} onClick={() => {window.location.href=""}}>Accueil</Button>
              <Button color="inherit" sx={{ fontFamily: "quasimoda" }} onClick={() => {window.location.href="#stepsDidayz"}}>Les étapes</Button>
              <Button color="inherit" sx={{ fontFamily: "quasimoda" }} onClick={() => {window.location.href="#opportunityDidayz"}}>Les occasions</Button>
              <Button color="inherit" sx={{ fontFamily: "quasimoda" }} onClick={() => {window.location.href="/blog"}}>Le blog</Button>
              <Button color="inherit" sx={{ fontFamily: "quasimoda" }} onClick={() => {window.location.href="/contactus"}}>Contact</Button>
            </Box>
          </Toolbar>
        </AppBar>
        <div className="mainBody">
          <AnimatePresence>
            <Routes>
              <Route path="/newpwd/:idTmpToken" element={<NewPassword />} ></Route>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/prices" element={<Prices />} />
              <Route path="/mentionslegales" element={<LegalNotice />} />
              <Route path="/contactus" element={<ContactUS />} />
              <Route path="/guide" element={<Guide />} />
              <Route path="/blog" element={<Blog />} />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
      {isFromPhone ?
        <div className="footerLabels" style={{ backgroundColor: "#000000", minHeight: "calc(50px + 5vw)", paddingTop: "calc(10px + 2vw)", paddingBottom: "calc(10px + 2vw)", display: "flex", justifyContent: "space-evenly"}}>
          <a href="/contactus">Contact</a>
          <a href="/blog">Blog</a>
          <a href="/mentionslegales">Mentions légales</a>
        </div> :
        <div className="footer">
          <img alt="Didayz" src={whitedidayz} style={{height: "calc(20px + 4vw)"}}></img>
          <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", justifyItems: "flex-start", columnGap: "4vw"}}>
            <div className="footerLabels" style={{display: "grid", gridTemplateRows: "1fr 1fr 1fr"}}>
              <label style={{fontWeight: "bold"}}>Produit</label>
              <a href="https://play.google.com/store/apps/details?id=fr.didayz.app" target="_blank" rel="noreferrer">Télécharger l'app</a>
              <a href="/guide">FAQ</a>
            </div>
            <div className="footerLabels" style={{display: "grid", gridTemplateRows: "1fr 1fr 1fr"}}>
              <label style={{fontWeight: "bold"}}>Didayz</label>
              <a href="/contactus">Contact</a>
              <a href="/mentionslegales">Mentions légales</a>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default App;