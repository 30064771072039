import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Carousel from 'react-material-ui-carousel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import desc0 from '../assets/desc0.png';
import desc1 from '../assets/desc1.png';
import desc2 from '../assets/desc2.png';

import step0 from '../assets/step0.jpg';
import step1 from '../assets/step1.jpg';
import step2 from '../assets/step2.jpg';
import step3 from '../assets/step3.jpg';
import step4 from '../assets/step4.jpg';
import step5 from '../assets/step5.jpg';

import idea0 from '../assets/idea0.jpg';
import idea1 from '../assets/idea1.jpg';
import idea2 from '../assets/idea2.jpg';
import idea3 from '../assets/idea3.jpg';
import idea4 from '../assets/idea4.jpg';
import idea5 from '../assets/idea5.jpg';
import idea6 from '../assets/idea6.jpg';
import idea7 from '../assets/idea7.jpg';
import idea8 from '../assets/idea8.jpg';

import { isFromPhone } from "./globalVariable.component";

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(68,55,193,1) 35%, rgba(0,212,255,1) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(68,55,193,1) 35%, rgba(0,212,255,1) 100%)',
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <LightbulbOutlinedIcon />,
    2: <PlaylistAddCheckOutlinedIcon />,
    3: <CreateOutlinedIcon />,
    4: <AddTaskOutlinedIcon />,
    5: <PublishOutlinedIcon />,
    6: <SendOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  {
    label: 'Trouver une bonne raison pour créer un Didayz',
    description: `J'aimerai créer un Didayz pour soutenir mon meilleur ami avant son mariage`,
    picture: step0,
    type: 'vertical',
  },
  {
    label: 'Préparer et rassembler le contenu',
    description:'Je regroupe différents contenus pour alimenter mon Didayz',
    picture: step1,
    type: 'vertical',
  },
  {
    label: 'Créer le Didayz',
    description: `Une fois tous les contenus récupérés, je télécharge l'application Didayz : je peux créer le Didayz !`,
    picture: step2,
    type: 'horizontal',
  },
  {
    label: 'Remplir les jours du Didayz',
    description: `J'alimente chaque jour avec un nouveau contenu (parmi les différents contenus rassemblés lors de l'étape 2)`,
    picture: step3,
    type: 'horizontal',
  },
  {
    label: 'Publier le Didayz',
    description: `Une fois mon Didayz complété à 100% (tous les jours sont paramétrés), je le publie !`,
    picture: step4,
    type: 'horizontal',
  },
  {
    label: 'Partager le Didayz à mon ami',
    description: `Je peux envoyer la clé d'accès de mon Didayz à mon meilleur ami qui pourra l'ajouter et consulter chaque jour le nouveau contenu`,
    picture: step5,
    type: 'vertical'
  }
];

const itemData = [
  {
    img: idea3,
    title: 'Avant une compétition',
    author: '@DidayzDeSoutien',
    tooltip: 'L\'outil idéal en tant que coach pour accompagner vos sportifs et prodiguer vos conseils dans les derniers jours avant une compétition',
  },
  {
    img: idea6,
    title: 'Challenge personnel',
    author: '@DidayzDeMotivation',
    tooltip: 'Parfait pour se challenger personnellement pendant un mois, en programmant un Didayz en amont pour se fixer un objectif quotidien (défis sportifs, sociaux, culturels, environnementaux, artistiques, ...)',
  },
  {
    img: idea4,
    title: 'Escape Game',
    author: '@DidayzDeDivertissement',
    cols: 2,
    tooltip: 'Didayz permet de créer des escapes games personnalisés facilement et rapidement. Poser des énigmes, bloquer l\'accès à certains jours sous condition de saisir un code, faites cogiter vos amis !',
  },
  {
    img: idea0,
    title: 'Calendrier de l\'avent traditionnel',
    author: '@Traditionnel',
    tooltip: 'Il est biensur possible de réaliser un calendrier de l\'avent traditionnel (25 jours avant Noël), en personnalisant chaque contenu',
  },
  {
    img: idea1,
    title: 'Avant un mariage',
    author: '@DidayzDeSoutien',
    tooltip: 'Quoi de mieux que d\'offrir tous les jours des messages réconfortants et émouvants pour accompagner les derniers jours stressants avant le mariage de son ami ?',
  },
  {
    img: idea2,
    title: 'Avant un examen',
    author: '@DidayzDeSoutien',
    tooltip: 'Difficile de soutenir un proche lorsque celui ci passe un concours, une certification importante à ses yeux... En passant par un Didayz, apportez votre soutien sans être trop intrusif : la personne peut consulter votre message lorsqu\'elle le décide dans sa journée',
  },
  {
    img: idea5,
    title: 'Découverte de playlist',
    author: '@DidayzDeDivertissement',
    tooltip: 'Faites découvrir à vos amis vos playlists préférées en partageant un nouveau morceau par jour',
  },
  {
    img: idea7,
    title: 'Entrainement un dessin /jour',
    author: '@DidayzDeMotivation',
    tooltip: 'Programmez un nouveau concept par jour à illustrer selon votre imagination et votre émotion du moment',
  },
  {
    img: idea8,
    title: 'A vous de jouer',
    author: '@AVourDeJouer',
    tooltip: 'Avec Didayz, tous les domaines de votre choix sont transposables sous la forme d\'un calendrier facile à mettre en oeuvre et fun à découvrir. Faites parler votre créativité !',
  },
];

function Home() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ display: "grid" }}>
      <Carousel
        NextIcon={<NavigateNextIcon/>}
        PrevIcon={<NavigateBeforeIcon/>}
        navButtonsProps={{
          style: {
              backgroundColor: 'transparent',
              color: '#4437C1',
              borderRadius: 0
          }
        }}  
        interval={null}
        autoPlay={false}
        animation="slide"
        duration={600}
        swipe={false}
        navButtonsAlwaysVisible={true}
      >
        <Grid container style={{height: isFromPhone ? "130vh" : "calc(100vh - 30px - 6vw)"}}>
          <Grid container item md={7} className="prezHome">
            <Grid item xs={12} style={{ display: "grid", alignItems: "flex-end" }}>
              <label className="taglineHome">Rendre chaque jour unique&nbsp;!</label>
            </Grid>
            <Grid item xs={12}>
              <label className="descHome">Didayz c'est le calendrier de l'avent pour chacun de vos évènements marquants&nbsp;!<br></br>C'est un cadeau original et personnalisé à offrir à vos proches pour les soutenir, les surprendre, leur témoigner votre amour, les accompagner, les informer, les challenger, les faire rire, les faire réfléchir, les faire pleurer, les faire travailler, les aider, ...</label>
              <Button variant="contained" onClick={() => {window.location.href="#opportunityDidayz"}} sx={{ mt: 1, mr: 1, fontFamily: "quasimoda" }}>
                Un Didayz pour toutes les occasions
              </Button>
            </Grid>
          </Grid>
          <Grid item md={5} style={{display: "grid", justifyContent: "center", paddingRight: "calc(30px + 2vw)", paddingLeft: "calc(30px + 2vw)", alignItems: "center"}}>
            <img alt="Didayz" style={{width: "100%", maxHeight: "calc(100vh - 40px - 7vw)", justifySelf: "flex-end", marginBottom: "5vw", borderRadius: 5}} src={desc0}></img>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item md={7} className="prezHome">
            <Grid item xs={12} style={{ display: "grid", alignItems: "flex-end" }}>
              <label className="taglineHome">Vous souhaitez créer un Didayz&nbsp;?</label>
            </Grid>
            <Grid item xs={12}>
              <label className="descHome">Didayz vous permet d'exprimer pleinement votre esprit créatif.<br></br>Chaque nouvelle journée que la personne découvrira est une opportunité pour vous de créer un contenu qui saura faire mouche !<br></br>Importez des vidéos, des sons, des images ou de simples textes pour un cadeau 100% personnalisé</label>
            </Grid>
            <Grid item xs={12}>
              <label className="descHome">Je créé mon Didayz</label>
              <Button variant="outlined" startIcon={<AppleIcon sx={{ color: "#4437C1" }}/>} sx={{ marginRight: 2}} >
                Apple
              </Button>
              <Button variant="contained" endIcon={<AndroidIcon />} onClick={() => { window.open('https://play.google.com/store/apps/details?id=fr.didayz.app', '_blank');  }}>
                Android
              </Button>
            </Grid>
          </Grid>
          <Grid item md={5} style={{display: "grid", justifyContent: "center", paddingRight: "calc(30px + 2vw)", paddingLeft: "calc(30px + 2vw)", alignItems: "center"}}>
            <img alt="Didayz" style={{width: "100%", maxHeight: "calc(100vh - 40px - 7vw)", justifySelf: "flex-end", marginBottom: "5vw", borderRadius: 5}} src={desc2}></img>
          </Grid>
        </Grid>
        <Grid container >
          <Grid container item md={7} className="prezHome">
            <Grid item xs={12} style={{ display: "grid", alignItems: "flex-end" }}>
              <label className="taglineHome">Vous avez reçu un Didayz&nbsp;?</label>
            </Grid>
            <Grid item xs={12}>
              <label className="descHome">Vous avez probablement une échéance importante qui arrive et une personne pense beaucoup à vous pendant cette période.<br></br>Jusqu'à l'échéance, un nouveau contenu créé exclusivement pour vous sera disponible chaque jour</label>
            </Grid>
            <Grid item xs={12}>
              <label className="descHome">Je consulte mon Didayz</label>
              <Button variant="outlined" startIcon={<AppleIcon sx={{ color: "#4437C1" }}/>} sx={{ marginRight: 2}} >
                Apple
              </Button>
              <Button variant="contained" endIcon={<AndroidIcon />} onClick={() => { window.open('https://play.google.com/store/apps/details?id=fr.didayz.app', '_blank');  }}>
                Android
              </Button>
            </Grid>
          </Grid>
          <Grid item md={5} style={{display: "grid", justifyContent: "center", paddingRight: "calc(30px + 2vw)", paddingLeft: "calc(30px + 2vw)", alignItems: "center"}}>
            <img alt="Didayz" style={{width: "100%", maxHeight: "calc(100vh - 40px - 7vw)", justifySelf: "flex-end", marginBottom: "5vw", borderRadius: 5}} src={desc1}></img>
          </Grid>
        </Grid>
      </Carousel>
      <Divider orientation="horizontal" variant="middle" sx={{ marginTop: 5 }} id="stepsDidayz"/>
      <Box sx={{ justifySelf: "center", margin: 10, display: "grid", gap: 2}}>
        <label style={{fontFamily: "quasimoda", fontSize: "calc(16px + 1vw)", textAlign: "center" }}>Je souhaite créer un Didayz, comment je procède&nbsp;?</label>
        <label style={{fontFamily: "quasimoda", fontSize: "calc(12px + 0.5vw)", textAlign: "center" }}>Rien de plus simple, voici quelques étapes à suivre pour que la surprise soit totale</label>
      </Box>
      <Box sx={{ width: 720, maxWidth: "80vw", justifySelf: "center", marginLeft: 2, marginRight: 2, }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === steps.length - 1 ? (
                    <Typography variant="caption">Dernière étape</Typography>
                  ) : null
                }
                StepIconComponent={ColorlibStepIcon}
              >
                <Typography dangerouslySetInnerHTML={{__html: step.label}} style={{ fontFamily: "quasimoda", fontSize: 18 }}></Typography>
              </StepLabel>
              <StepContent>
                {step.type === "horizontal" && !isFromPhone ?
                <Card sx={{ display: 'grid', gridTemplateColumns: "1fr 2fr", padding: "5px"}}>
                  <CardMedia
                    component="img"
                    sx={{objectFit: "contain", alignSelf: "center", borderRadius: 2}}
                    image={step.picture}
                    title={"Step"+index}
                  />
                  <Box sx={{ display: 'grid', justifyContent: 'space-between', height: "100%" }}>
                    <CardContent >
                      <Typography dangerouslySetInnerHTML={{__html: step.description}} className="descHome"></Typography>
                    </CardContent>
                    <CardActions sx={{ alignSelf: "flex-end" }}>
                      <Button size="small" onClick={handleNext} style={{ fontFamily: "quasimoda" }}>{index === steps.length - 1 ? 'Terminer' : 'Etape suivante'}</Button>
                      <Button size="small" disabled={index === 0} onClick={handleBack} style={{ fontFamily: "quasimoda" }}>Etape précédente</Button>
                    </CardActions>
                  </Box>   
                </Card> :
                <Card>
                  <CardMedia
                    sx={{ height: 180, backgroundSize: step.type === "horizontal" ? "contain" : "cover" }}
                    image={step.picture}
                    title={"Step"+index}
                  />
                  <CardContent>
                    <Typography dangerouslySetInnerHTML={{__html: step.description}} className="descHome"></Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={handleNext} style={{ fontFamily: "quasimoda" }}>{index === steps.length - 1 ? 'Terminer' : 'Etape suivante'}</Button>
                    <Button size="small" disabled={index === 0} onClick={handleBack} style={{ fontFamily: "quasimoda" }}>Etape précédente</Button>
                  </CardActions>
                </Card>
                }
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography className="descHome">Vous savez comment créer et partager un Didayz. A vous de jouer !</Typography>
            <Button variant="contained" startIcon={<AndroidIcon />} onClick={() => { window.open('https://play.google.com/store/apps/details?id=fr.didayz.app', '_blank');  }} sx={{ mt: 1, mr: 1, fontFamily: "quasimoda" }}>
              Je créé mon premier Didayz
            </Button>
            <Button variant="contained" startIcon={<AppleIcon />} sx={{ mt: 1, mr: 1, fontFamily: "quasimoda" }}>
              Je créé mon premier Didayz
            </Button>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1, fontFamily: "quasimoda" }}>
              Revoir les étapes
            </Button>
          </Paper>
        )}
      </Box>
      <Divider orientation="horizontal" variant="middle" sx={{ marginBottom: 10, marginTop: 10 }} id="opportunityDidayz"/>
      <Box sx={{ justifySelf: "center", marginLeft: 10, marginRight: 10, marginBottom: 5, display: "grid", gap: 2}}>
        <label style={{fontFamily: "quasimoda", fontSize: "calc(16px + 1vw)", textAlign: "center" }}>Pour quelles occasions je peux faire un Didayz&nbsp;?</label>
      </Box>
      <ImageList sx={{ width: 720, maxWidth: "80vw", justifySelf: "center" }}>
        {itemData.map((item) => (
          <ImageListItem key={item.img} cols={item.cols} rows={item.rows}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.title}
              subtitle={item.author}
              sx={{
                "& .MuiImageListItemBar-title": { fontSize: isFromPhone ?  10 : 16, textOverflow: "ellipsis" }, //styles for title
                "& .MuiImageListItemBar-subtitle": { fontSize: isFromPhone ?  8 : 12, textOverflow: "ellipsis" }, //styles for subtitle
              }}
              actionIcon={
                <Tooltip title={item.tooltip}>
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`Informations sur ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

export default Home