import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import didayz_icone from '../assets/didayz/didayz_icone_small.png';

import article1 from '../assets/blog/article1/presentation.jpg';
/*import article2 from '../assets/blog/article2/presentation.png';
import article3 from '../assets/blog/article3/presentation.jpg';
import article4 from '../assets/blog/article4/presentation.jpg';*/

import { isFromPhone } from "./globalVariable.component";

const cardData = [
  {
    idx: 0,
    img: article1,
    tag: 'Histoire',
    title: 'Calendriers de l\'avent, histoire et avenir !',
    description: 'Des origines allemandes du calendrier de l\'avent jusqu\'à la naissance de Didayz, voici un tour d\'horizon de l\'évolution de cet indémodable qui a traversé les décennies en s\'adaptant aux envies des petits... et des grands.',
    fullTexte: '',
    authors: [{ name: 'Adrien Boigné', avatar: didayz_icone }],
    date: '15 nov 2024',
  },
  /*{
    idx: 1,
    img: article2,
    tag: 'Consommation',
    title: 'Des calendriers pour tous les goûts',
    description: 'Chocolats, boissons chaudes, alcools, jouets, articles de beauté, ... aucun domaine ne résiste aux calendriers de l\'avent, pour le plus grand plaisir des consommateurs.',
    authors: [{ name: 'Adrien Boigné', avatar: didayz_icone }],
    date: '15 nov 2024',
  },
  {
    idx: 2,
    img: article3,
    tag: 'Créativité',
    title: 'Calendriers traditionnels vs Didayz',
    description: 'Le succès annuel des calendriers de l\'avent démontre l\'intérêt du public pour cette tradition qui permet d\'accompagner de surprises les derniers jours avant Noël. Pourtant, des limitations existent ! Voici comment Didayz propose d\'y pallier...',
    authors: [{ name: 'Adrien Boigné', avatar: didayz_icone }],
    date: '15 nov 2024',
  },,
  {
    idx: 3,
    img: article4,
    tag: 'Didayz',
    title: 'Pourquoi juste pour Noël ?',
    description: 'Chaque évènement, aussi important, stressant, excitant qu\'il soit mérite son calendrier de l\'avent ! Plus besoin d\'attendre la fin d\'année pour connaitre le plaisir de découvrir une surprise tous les jours.',
    authors: [{ name: 'Adrien Boigné', avatar: didayz_icone }],
    date: '15 nov 2024',
  },*/
];


function Author({ authors, date }) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center"
          }}
        >
          <AvatarGroup max={3}>
            {authors.map((author, index) => (
              <Avatar
                key={index}
                alt={author.name}
                src={author.avatar}
                sx={{ width: 24, height: 24 }}
              />
            ))}
          </AvatarGroup>
          <Typography style={{ fontFamily: 'quasimoda' }} variant="caption">
            {authors.map(author => author.name).join(", ")}
          </Typography>
        </Box>
        <Typography style={{ fontFamily: 'quasimoda' }} variant="caption">{date}</Typography>
      </Box>
    )
}


export default function Blog() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = index => {
    setFocusedCardIndex(index)
  }

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginLeft: 5, marginRight: 5, }}>
      <div>
        <label className="taglineHome" style={{fontSize: "calc(30px + 2vw)"}}>
          Nos articles 
        </label>
        <label className="descHome">Consultez tous nos articles sur les calendriers de l'avent mais aussi et surtout sur Didayz et ses possibilités infinies</label>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: isFromPhone ? "1fr" : "1fr 1fr" }}>
      {cardData.map((item) => (
        <Grid sx={{ margin: 1 }} key={item.idx}>
          <Card
            variant="outlined"
            onFocus={() => handleFocus(0)}
            onBlur={handleBlur}
            tabIndex={item.idx}
            className={focusedCardIndex === 0 ? 'Mui-focused' : ''}
            sx={{
              ':hover': {
                boxShadow: 5, // theme.shadows[20]
                cursor: "pointer",
              },
            }}
          >
            <CardMedia
              component="img"
              alt="green iguana"
              image={item.img}
              sx={{
                aspectRatio: '16 / 9',
                borderBottom: '1px solid',
                borderColor: 'divider',
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="caption" component="div">
                {item.tag}
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ overflow: "ellipsis"}} gutterBottom dangerouslySetInnerHTML={{__html: item.description}} />
            </CardContent>
            <Author authors={item.authors} date={item.date} />
          </Card>
        </Grid>
      ))}
      </div>
    </Box>
  );
}
